import Vue from 'vue'
import Vuex from 'vuex'
import { getInfo } from '../utils/utils.js'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    imgUrl: 'https://shangshui-center-store.campgrounds.cn/admin/img/imageFile',
    navigationData: [],
    history: [{
      path: '/index',
      fullPath: '/index',
      meta: { name: '控制台' }
    }],
    info: {},
    isCollapse: false,
  },
  mutations: {
    changeNavigationData(state, data) {
      state.navigationData = data

    },
    changeColl(state, data) {
      state.isCollapse = data
    },
    getroute(state, data) {
      if (data.index) {
        state.history.splice(data.index, 1)
        return
      } else if (data.index2) {
        state.history[data.index2] = data.info
        state.history.splice(data.index2, 1)
      } else {
        state.history.push(data)
      }
      if (state.history.length > 20) {
        state.history.splice(1, 1)
      }
    },
    setUserInfo(state, data) {
      state.info = data
    }
  },
  actions: {
  },
  modules: {
  }
})
