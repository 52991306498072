import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { getNavn } from './utils/utils.js'
import { getOptionTask } from './api/home.js'
import ElementUI from 'element-ui'
import {
  Message,
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';

Vue.use(Avue);
Vue.use(ElementUI)
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts
import axios from 'axios'
Vue.prototype.$axios = axios
Vue.prototype.$getNavn = getNavn
Vue.prototype.$getOptionTask = getOptionTask
Vue.config.productionTip = false
import 'default-passive-events'
import animate from 'animate.css'
Vue.use(animate)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')



